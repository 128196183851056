import React, { useEffect, useState } from 'react';
import { ProfileStyled } from './styled';
import { Form, Input, Spin } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../stores';
import Toast from '../../components/toast';
import { BG_COVER } from '../../theme/images';
import { getUserInfoAction } from '../../stores/screens/auth/auth.action';
import { oneDecimalPlace } from '../../utils/number';
import { Images } from '../../theme';
import Misc from '../../utils/misc';
import Storage from '../../utils/storage';
import ButtonImage from '../../components/button-img';

const Profile = () => {
  const dispatch = useAppDispatch();
  const { userInfo, isLoading } = useAppSelector((state) => state.auth);
  const [params, setParams] = useState('');

  const getInfo = async () => {
    await dispatch(
      getUserInfoAction({ accessToken: Storage.get('ACCESS_TOKEN') })
    );
  };

  useEffect(() => {
    if (userInfo) {
      const prm = btoa(
        JSON.stringify({
          accountEmail: userInfo?.email,
          referral: userInfo?.referral_code,
        })
      );
      setParams(prm);
    }
  }, [userInfo]);

  useEffect(() => {
    getInfo();
    /* eslint-disable */
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(userInfo?.wallet_address).then(() => {
      Toast.show('Copied to clipboard!');
    });
  };

  return (
    <ProfileStyled bgImage={BG_COVER}>
      {isLoading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
              }}
              spin
            />
          }
        />
      )}

      <div className="title">Profile</div>
      <div className="desktop-bg"></div>
      <div className="divider"></div>

      <div className="dp-flex">
        <div className="user">
          <div className="mobile-bg"></div>
          {userInfo?.avatar_url ? (
            <img
              className="avatar"
              width={48}
              height={48}
              src={userInfo?.avatar_url}
              alt="logo-user"
            />
          ) : (
            <div className="logo-default">
              <UserOutlined className="icon-user" />
            </div>
          )}
        </div>
        <Form className="form-profile">
          <Form.Item>
            <label>Wallet address</label>
            <Input
              value={Misc.trimPublicAddress(userInfo?.wallet_address || '', 8)}
              suffix={<img src={Images.COPY} alt="copy" onClick={handleCopy} />}
              disabled
            />
          </Form.Item>
          <Form.Item>
            <label>Email</label>
            <Input
              addonBefore={<img src={Images.EMAIL} alt="email" />}
              value={userInfo?.email || ''}
              disabled
            />
          </Form.Item>
          {userInfo?.telegram_coins_balance !== null && (
            <Form.Item>
              <label>Coin Balance</label>
              <Input
                value={oneDecimalPlace(userInfo?.telegram_coins_balance)}
                disabled
              />
            </Form.Item>
          )}
          <ButtonImage
            type="button"
            height={50}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
          >
            <a
              className="color-white"
              href={`${process.env.REACT_APP_TELEGRAM_SHORT_LINK}?startapp=${params}`}
              target="_blank"
            >
              {userInfo?.linked ? 'Play MCC Game' : 'Link to Telegram'}
            </a>
          </ButtonImage>
        </Form>
      </div>
    </ProfileStyled>
  );
};

export default Profile;
