import React, { useEffect } from 'react';
import { ProfileWrapper } from './styled';
import { useWeb3Auth } from '../../contexts/web3auth';
import { Outlet, useNavigate } from 'react-router';
import Aos from 'aos';
import { LOCAL_STORAGE_KEY } from '../../constants';
import ManagementHeader from '../Management/Header';
import ScrollToTop from '../../utils/scrollToTop';
import MaskLoading from '../../components/mask-loading';
import Confirmable from '../../components/confirmable';
import Storage from './../../utils/storage';

const ProfileLayout = () => {
  const { accessToken, currentAccount, userInfo } = useWeb3Auth();

  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (!Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN)) {
      navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileWrapper>
      <ManagementHeader
        accessToken={accessToken}
        publicAddress={currentAccount}
        userInfo={userInfo}
      />
      <div className="dp-flex full-screen">
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </div>

      <MaskLoading ref={(ref) => MaskLoading.setInstance(ref)} />
      <Confirmable ref={(ref) => Confirmable.setInstance(ref)} />
    </ProfileWrapper>
  );
};

export default ProfileLayout;
