import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, Select, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormControl from '../../../components/form-control';
import {
  FILE_DIMENSION,
  FILE_OPTIONS,
  FILE_SIZE,
  FILE_TYPE_IMAGE,
  FILE_TYPE_SLIDE,
  FILE_TYPE_VIDEO,
  MAX_FILE_SIZE,
  STATUS_NETWORK,
  TYPE_FILE,
} from '../../../constants';
import { Images } from '../../../theme';
import Files from '../Files';
import ButtonImage from './../../../components/button-img';
import { CreateModalWrapper } from './styled';
import { useAppDispatch, useAppSelector } from '../../../stores';
import {
  createRequestAction,
  preSignUrlAction,
  uploadToS3Action,
} from '../../../stores/screens/requestManagement/management.action';
import { schema } from './shema';
import { aspectRatioRercent } from '../../../utils/common';
import countryCode from './countryCode.json';

const CreateModal = ({
  isOpenModal,
  title,
  onCancel,
  setLoading,
  setModalSuccess,
  ...props
}) => {
  const [fileUpload, setFileUpload] = useState([]);
  const [fileThumbnail, setFileThumbnail] = useState([]);
  const [selectType, setSelectType] = useState('');
  const [aspectRatio, setAspectRatio] = useState('');
  const [srcIcon, setSrcIcon] = useState('');
  const [errorSize, setErrorSize] = useState('');
  const [errorSize2, setErrorSize2] = useState('');
  const slideFiles = [0, 1, 2, 3, 4];

  const dispatch = useAppDispatch();
  const { aspectRatios } = useAppSelector((state) => state.requestManagement);
  const {
    handleSubmit,
    // getValues,
    setValue,
    trigger,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const splitFileNameAndType = (fileName) => {
    var dotIndex = fileName.lastIndexOf('.');

    if (dotIndex === -1 || dotIndex === fileName.length - 1) {
      return null;
    }

    var name = fileName.substring(0, dotIndex);
    var type = fileName.substring(dotIndex + 1);

    return {
      name: name,
      type: type,
    };
  };

  const onSubmit = async (data) => {
    trigger();

    if (isValid && !errorSize) {
      try {
        const dialCodes = countryCode.find(
          (item) => item.code === data.country)?.dialCodes;
        
        const phoneNumber = `${dialCodes}${data.phone}`;

        onCancel();
        setLoading(true);

        const uploadFiles = async (files) => {
          const urls = await Promise.all(
            files.map(async (file) => {
              const { meta, payload } = await dispatch(
                preSignUrlAction({
                  fileName: splitFileNameAndType(file.name).name,
                  fileType: splitFileNameAndType(file.name).type,
                  isThumbnail:
                    fileThumbnail.length && !FILE_TYPE_VIDEO.includes(file.type)
                      ? true
                      : false,
                })
              );

              if (
                meta.requestStatus === STATUS_NETWORK.FULFILLED &&
                payload.data
              ) {
                const res = await dispatch(
                  uploadToS3Action({
                    url: payload.data.preSignedURL,
                    file,
                  })
                );

                if (res.meta.requestStatus === STATUS_NETWORK.FULFILLED) {
                  return payload.data.url;
                }
              }
              return null;
            })
          );

          return urls.filter(Boolean); // Remove null values
        };

        const arrUrl = fileUpload.length ? await uploadFiles(fileUpload) : [];

        const thumbnailUrl = fileThumbnail.length
          ? await uploadFiles(fileThumbnail)
          : [];

        const createPayload = {
          company_name: data.companyName,
          name: data.title,
          phone_number: phoneNumber,
          type: data.type.toUpperCase(),
          url: data.URL,
          content:
            data.type === TYPE_FILE.IMAGE || data.type === TYPE_FILE.VIDEO
              ? arrUrl[0]
              : null,
          introduce: data.introduction,
          slides: data.type === TYPE_FILE.SLIDE ? arrUrl : null,
          aspect_ratio: data.aspect_ratio,
          thumbnail: data.type === TYPE_FILE.VIDEO ? thumbnailUrl[0] : null,
        };

        const res = await dispatch(createRequestAction(createPayload));
        setFileUpload([]);
        reset({});
        setSelectType('');
        setLoading(false);

        if (res?.meta?.requestStatus === STATUS_NETWORK.REJECTED) {
          notification.error({
            message: res.payload.message,
            duration: 5,
          });
          return;
        }
        setModalSuccess(true);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setFileUpload([]);
    reset({});
    setSelectType('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let src = '';
    switch (selectType) {
      case TYPE_FILE.IMAGE:
        src = Images.ICON_IMAGE;
        break;
      case TYPE_FILE.VIDEO:
        src = Images.ICON_VIDEO;
        break;
      case TYPE_FILE.SLIDE:
        src = Images.ICON_IMAGE;
        break;
      default:
        src = Images.UPLOAD_ICON;
        break;
    }
    setSrcIcon(src);
  }, [selectType]);

  const handleFileChange = (file) => {
    if (!file) return;
    setErrorSize('');
    const maxFileSize = file?.type.match(/^image\//) ? FILE_SIZE.IMAGE : FILE_SIZE.VIDEO;

    if ((selectType === TYPE_FILE.SLIDE) &&
      !FILE_TYPE_SLIDE.includes(file?.type)) {
      setErrorSize(`Only accepts images in PNG, JPEG format.`);
      return;
    }

    if (
      selectType === TYPE_FILE.VIDEO &&
      !FILE_TYPE_VIDEO.includes(file?.type)
    ) {
      setErrorSize(`Only accepts videos in MP4 or MOV format.`);
      return;
    }

    if (
      (selectType === TYPE_FILE.IMAGE || selectType === TYPE_FILE.SLIDE) &&
      !FILE_TYPE_IMAGE.includes(file?.type)
    ) {
      setErrorSize(`Only accepts images in PNG, JPEG or GIF format.`);
      return;
    }

    if (file?.size > maxFileSize * MAX_FILE_SIZE) {
      setErrorSize(`Maximum file size ${maxFileSize}MB`);
      return;
    }
    if (selectType === TYPE_FILE.IMAGE || selectType === TYPE_FILE.SLIDE) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width > FILE_DIMENSION || height > FILE_DIMENSION) {
          setErrorSize(`Maximum demensions ${FILE_DIMENSION} x ${FILE_DIMENSION} px`);
          URL.revokeObjectURL(objectUrl);
          return;
        } else {
          setErrorSize('');
          let temp = [...fileUpload];
          temp.push(file);
          setFileUpload([...temp]);
        }
      };
    }

    if (selectType === TYPE_FILE.VIDEO) {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;

        if (duration > 600) {
          setErrorSize(`Video must not be longer than 10 minutes.`);
          return;
        }

        let temp = [...fileUpload];
        temp.push(file);
        setFileUpload([...temp]);
      };
      video.src = URL.createObjectURL(file);
    } 
  };

  const handleUploadThumbnail = (file) => {
    if (!file) return;
    setErrorSize2('');
    const maxFileSize = 3;

    if (file?.size > maxFileSize * MAX_FILE_SIZE) {
      setErrorSize2(`Maximum file size ${maxFileSize}MB`);
      return;
    }
    if (!FILE_TYPE_IMAGE.includes(file?.type)) {
      setErrorSize2(`Only accepts images in PNG or JPEG format.`);
      return;
    }

    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.src = objectUrl;
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      if (width > FILE_DIMENSION || height > FILE_DIMENSION) {
        setErrorSize2(`Maximum demensions ${FILE_DIMENSION} x ${FILE_DIMENSION} px`);
        URL.revokeObjectURL(objectUrl);
        return;
      } else {
        setErrorSize2('');
        let temp = [...fileThumbnail];
        temp.push(file);
        setFileThumbnail([...temp]);
      }
    };
  };

  const handleDeleteFile = (file) => {
    const temp = fileUpload.filter((item) => item.uid !== file.uid);

    setFileUpload([...temp]);
    if (!temp.length) setValue('files', undefined);
    setErrorSize('');
  };

  const handleDeleteThumbnail = (file) => {
    const temp = fileThumbnail.filter((item) => item.uid !== file.uid);
    setFileThumbnail([...temp]);
    if (!temp.length) setValue('thumbnail', undefined);
  };

  const renderIconCountry = (item) => {
    return (
      <div className='item-country'>
        <img
          src={item.image} alt="icon"
          style={{
            width: '24px',
            height: '24px',
            marginRight: '5px',
          }}
        />
        <span>{`${item.name} (${item.dialCodes})`}</span>
      </div>
    );
  };

  return (
    <CreateModalWrapper
      open={isOpenModal}
      title={title}
      closable={false}
      footer={null}
      centered
      ratio={aspectRatioRercent(aspectRatio)}
      {...props}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="dp-flex mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="companyName">
              Company name<span className="required">*</span>
            </label>
            <Controller
              name="companyName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  autoComplete="off"
                  id="companyName"
                  name="companyName"
                  placeholder="Company name"
                  maxLength={30}
                  onChange={onChange}
                  value={value || ''}
                ></Input>
              )}
            />
            {errors?.companyName && (
              <p className="error">{errors.companyName.message}</p>
            )}
          </FormControl>

          <FormControl backgroundColor flex>
            <label htmlFor="url">
              URL<span className="required">*</span>
            </label>
            <Controller
              name="URL"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  autoComplete="off"
                  id="url"
                  name="url"
                  placeholder="URL"
                  onChange={onChange}
                  value={value || ''}
                ></Input>
              )}
            />
            {errors?.URL && <p className="error">{errors.URL.message}</p>}
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="title">
              Title<span className="required">*</span>
            </label>
            <Controller
              name="title"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  autoComplete="off"
                  id="title"
                  name="title"
                  placeholder="Title"
                  onChange={onChange}
                  value={value || ''}
                ></Input>
              )}
            />
            {errors?.title && <p className="error">{errors.title.message}</p>}
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="introduction">
              Introduction<span className="required">*</span>
            </label>
            <Controller
              name="introduction"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input.TextArea
                  autoComplete="off"
                  id="introduction"
                  name="introduction"
                  placeholder="Introduction"
                  onChange={onChange}
                  value={value || ''}
                  rows={5}
                />
              )}
            />
            {errors?.introduction && (
              <p className="error">{errors.introduction.message}</p>
            )}
          </FormControl>
        </div>
        <div className="dp-flex mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="type">
              Country<span className="required">*</span>
            </label>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="country"
                  name="country"
                  placeholder="Country"
                  options={countryCode.map((item) => ({
                    value: item.code,
                    label: renderIconCountry(item),
                    text: `${item.name} (${item.dialCodes})`,
                  }))}
                  onChange={onChange}
                  showSearch
                  filterOption={(input, option) =>
                    option?.text?.toLowerCase().includes(input?.toLowerCase())
                  }
                  className='custom-select'
                  popupClassName="custom-option"
                  suffixIcon={
                    <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
                  }
                ></Select>
              )}
            />
            {errors?.type && <p className="error">{errors.type.message}</p>}
          </FormControl>
          <FormControl backgroundColor flex>
            <label htmlFor="phone">
              Phone number <span className="required">*</span>
            </label>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  autoComplete="off"
                  id="phone"
                  name="phone"
                  placeholder="Phone number"
                  onChange={onChange}
                  value={value || null}
                ></Input>
              )}
            />
            {errors?.phone && <p className="error">{errors.phone.message}</p>}
          </FormControl>
        </div>
        <div className='mb-10'>
          <FormControl backgroundColor flex>
            <label htmlFor="type">
              Type<span className="required">*</span>
            </label>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="type"
                  name="type"
                  placeholder="Type"
                  options={FILE_OPTIONS}
                  onChange={(type) => {
                    onChange(type);
                    setSelectType(type);
                    setFileUpload([]);
                    setErrorSize('');
                  }}
                  popupClassName="custom-option"
                  suffixIcon={
                    <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
                  }
                ></Select>
              )}
            />
            {errors?.type && <p className="error">{errors.type.message}</p>}
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="title">
              Aspect ratio<span className="required">*</span>
            </label>
            <Controller
              name="aspect_ratio"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  id="aspect_ratio"
                  name="aspect_ratio"
                  placeholder="Select Aspect ratio"
                  value={value}
                  options={aspectRatios}
                  onChange={(type) => {
                    onChange(type);
                    setAspectRatio(type);
                  }}
                  popupClassName="custom-option"
                  suffixIcon={
                    <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
                  }
                ></Select>
              )}
            />
            {errors?.aspect_ratio && (
              <p className="error">{errors.aspect_ratio.message}</p>
            )}
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl backgroundColor flex>
            <label htmlFor="files">
              Contain<span className="required">*</span>
            </label>
            <Controller
              name="files"
              control={control}
              render={({ field: { onChange } }) => (
                <>
                  {selectType !== TYPE_FILE.SLIDE ? (
                    <>
                      <Files
                        fileUpload={fileUpload[0]}
                        handleFileChange={(file) => {
                          handleFileChange(file);
                        }}
                        onChange={(file) => onChange(file)}
                        handleDeleteFile={(file) => handleDeleteFile(file)}
                        selectType={selectType}
                        srcIcon={srcIcon}
                        aspectRatio={aspectRatio}
                      />
                    </>
                  ) : (
                    <div className="dp-flex">
                      {slideFiles.map((item) => {
                        return (
                          <Files
                            key={item}
                            fileUpload={fileUpload[item]}
                            handleFileChange={(file) => {
                              handleFileChange(file);
                            }}
                            onChange={(file) => onChange(file)}
                            handleDeleteFile={(file) => handleDeleteFile(file)}
                            selectType={selectType}
                            srcIcon={srcIcon}
                            aspectRatio={aspectRatio}
                          />
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            />
            {errors?.files && <p className="error">{errors.files.message}</p>}
            {errorSize && <p className="error">{errorSize}</p>}
          </FormControl>
        </div>
        {selectType === TYPE_FILE.VIDEO && (
          <div className="mb-10">
            <FormControl backgroundColor flex>
              <label htmlFor="thumbnail">
                Thumbnail<span className="required">*</span>
              </label>
              <Controller
                name="thumbnail"
                control={control}
                render={({ field: { onChange } }) => (
                  <div className="size-thumbnail">
                    <Files
                      className="custom-thumbnail"
                      fileUpload={fileThumbnail[0]}
                      handleFileChange={(file) => {
                        handleUploadThumbnail(file);
                      }}
                      onChange={(file) => onChange(file)}
                      handleDeleteFile={(file) => handleDeleteThumbnail(file)}
                      selectType={TYPE_FILE.IMAGE}
                      srcIcon={Images.ICON_IMAGE}
                      aspectRatio={aspectRatio}
                    />
                  </div>
                )}
              />
              {errors?.thumbnail && (
                <p className="error">{errors.thumbnail.message}</p>
              )}
              {errorSize2 && <p className="error">{errorSize2}</p>}
            </FormControl>
          </div>
        )}
        <div className="dp-flex btn-group">
          <ButtonImage
            onClick={() => {
              setFileUpload([]);
              reset({});
              setSelectType('');
              onCancel();
            }}
            type="cancel"
            height={40}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
          >
            Cancel
          </ButtonImage>
          <ButtonImage
            onClick={handleSubmit(onSubmit)}
            type="action"
            height={40}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
          >
            Request
          </ButtonImage>
        </div>
      </Form>
    </CreateModalWrapper>
  );
};

export default CreateModal;
