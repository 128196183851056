import { notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../stores';
import Storage from '../../utils/storage';
import ButtonImage from '../button-img';
import { Images } from '../../theme';
import { ModalVerifyCodeStyled } from './styled';
import { connectTelegramAccountAction } from '../../stores/screens/telegram/telegram.action';
import { LoadingOutlined } from '@ant-design/icons';
import { STATUS_NETWORK } from '../../constants';

export const ModalLinkTelegram = ({ closeModal = () => {} }) => {
  const dispatch = useAppDispatch();

  const encodeProfile = Storage.get('TELEGRAM_PROFILE');

  const [isSuccessLinkTelegram, setSuccessLinkTelegram] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    if (encodeProfile && isValidJson(encodeProfile)) {
      setProfile(JSON.parse(encodeProfile));
    } else {
      notification.error({
        message: 'The data provided from Telegram is incorrect.',
        duration: 8,
      });
      closeModal();
      setProfile(null);
    }
  }, []);

  const handleLinkTelegram = async () => {
    setLoading(true);
    const res = await dispatch(connectTelegramAccountAction(profile));
    setLoading(false);
    if (res?.meta?.requestStatus === STATUS_NETWORK.REJECTED) {
      notification.error({
        message: res?.payload?.message,
        duration: 8,
      });
      closeModal();
      return;
    }
    setSuccessLinkTelegram(true);
  };

  return (
    <ModalVerifyCodeStyled>
      {loading ? (
        <div className="content">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 50,
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <>
          {!isSuccessLinkTelegram ? (
            <>
              <div className="content">
                <h2>LINK TO TELEGRAM</h2>
                <h4>Do you agree to link to telegram account:</h4>
                <h4>Username: {profile?.user_name}</h4>
                <h4>TelegramId: {profile?.uuid}</h4>
              </div>
              <div className="action-box">
                <ButtonImage
                  onClick={() => closeModal()}
                  src={Images.CANCEL_BTN}
                  height={50}
                  className="btn-submit"
                  type="blue"
                >
                  <div className={`generate`}>Cancel</div>
                </ButtonImage>
                <ButtonImage
                  onClick={() => handleLinkTelegram()}
                  src={Images.GENERATE_BTN}
                  height={50}
                  className="btn-submit"
                >
                  OK
                </ButtonImage>
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <h4>Link to telegram account successfully!</h4>
              </div>
              <div className="action-box">
                <ButtonImage
                  onClick={() => closeModal()}
                  src={Images.GENERATE_BTN}
                  height={50}
                  className="btn-submit"
                >
                  OK
                </ButtonImage>
              </div>
            </>
          )}
        </>
      )}
    </ModalVerifyCodeStyled>
  );
};
