import styled from 'styled-components';

export const ProfileStyled = styled.div`
  width: 100%;
  padding: 30px;

  color: #fff;
  .color-white {
    color: #fff;
    width: 100%;
    padding: 10px 5px;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin: 0 auto;
    width: 50%;
  }

  .divider {
    width: 50%;
    margin: 0 auto;
    border-bottom: 1px solid #ececec;
    margin-bottom: 30px;
  }

  .dp-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-direction: column;
  }

  .mobile-bg {
    display: block;
    background-image: url(${(props) => props.bgImage});
    width: 50%;
    height: 200px;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
    margin: 0 auto;
  }

  .logo-default {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    box-shadow: 0px 0px 8px 2px #2b37bd;
    background: linear-gradient(180deg, #2773da 0%, #68d8ff 100%),
      radial-gradient(63% 63% at 50% 50%, #2773da 0%, rgba(0, 0, 0, 0) 100%);
    .icon-user {
      font-size: 60px;
    }
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user {
    width: 100%;
    position: relative;
  }
  .avatar {
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    box-shadow: 0px 0px 8px 2px #2b37bd;
  }

  .btn-connect {
    border-radius: 50px;
    height: 40px;
    margin: auto;
    background-color: #045afc;
    color: #fff;
    border-color: #045afc;
  }

  .form-profile {
    width: 500px;

    .ant-form-item {
      color: #fff;
    }

    .ant-input {
      background: #041d5a;
      border-color: #156df2;
      color: #fff;
      height: 44px;
    }

    .ant-form-item-control-input-content {
      label {
        display: block;
        margin-bottom: 10px;
      }
    }

    .ant-input-group-wrapper,
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      background: #041d5a;
      border-color: #156df2;
      color: #fff;
    }

    .ant-input-suffix {
      cursor: pointer;
    }

    label {
      color: #a8aeba;
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    .dp-flex {
      flex-direction: column;
    }
    .title {
      width: 100%;
    }

    .divider {
      width: 100%;
      display: block;
    }
    .user {
      width: 100%;
      position: relative;
    }
    .desktop-bg {
      display: none;
    }
    .mobile-bg {
      display: block;
      background-image: url(${(props) => props.bgImage});
      width: 100%;
      height: 150px;
      background-size: cover;
      background-position: center center;
      border-radius: 10px;
    }

    .avatar {
      width: 150px;
      height: 150px;
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      border: 5px solid black;
    }

    .form-profile {
      width: 100%;

      .ant-input {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .btn-connect {
      margin-top: 10px;
      width: 100%;
    }
  }
`;
