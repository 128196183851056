import { Form, Input } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { EyeOutlined } from "@ant-design/icons";
import FormControl from '../../../components/form-control';
import { TYPE_FILE_UPPERCASE } from '../../../constants';
import { useAppSelector } from '../../../stores';
import ButtonImage from './../../../components/button-img';
import { DetailModalWrapper, PreviewModalWrapper } from './styled';
import { Images } from '../../../theme';
import ReactPlayer from 'react-player';
import useVideoPlayer from '../../../hooks/useVideoPlayer';
import { aspectRatioRercent, handleAspectRatio } from '../../../utils/common';
import { ImageWrapper } from '../Files/styled';
const DetailModal = ({
  id,
  setModalDetail,
  onDelete,
  onPreviewFile,
  ...props
}) => {
  const { requestDetail, aspectRatios } = useAppSelector((state) => state.requestManagement);
  const [modalPreview, setModalPreview] = useState(false);
  const [srcSelected, setSrcSelected] = useState('');
  const [isThumbnail, setThumbnail] = useState(false);
  const videoRef = useRef();
  const {
    playerState,
    togglePlay,
    skipBackward,
    skipForward,
    handleVideoProgress,
    toggleMute,
    toggleFullScreen,
    currentTime,
    totalTime,
    setPlayerState,
    handlePlayVideo
  } = useVideoPlayer(videoRef);

  const aspectRatioValue = useMemo(() => {
    const itemSlected = aspectRatios?.find((item) => item.value === requestDetail.aspect_ratio)
    return itemSlected ? itemSlected.label : null
  }, [aspectRatios, requestDetail.aspect_ratio])
  const width = handleAspectRatio(requestDetail?.aspect_ratio).width
  const height = handleAspectRatio(requestDetail?.aspect_ratio).height
  return (
    <DetailModalWrapper
      ratio={aspectRatioRercent(requestDetail?.aspect_ratio)}
      {...props}>
      <Form>
        <div className="dp-flex mb-10">
          <FormControl>
            <label htmlFor="companyName">
              Company name<span className="required">*</span>
            </label>
            <Input
              id="companyName"
              name="companyName"
              value={requestDetail.company_name}
              disabled
            ></Input>
          </FormControl>

          <FormControl>
            <label htmlFor="url">
              URL<span className="required">*</span>
            </label>
            <Input
              id="url"
              name="url"
              value={requestDetail.url}
              disabled
            ></Input>
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl>
            <label htmlFor="title">
              Title<span className="required">*</span>
            </label>
            <Input
              id="title"
              name="title"
              value={requestDetail.name}
              disabled
            ></Input>
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl>
            <label htmlFor="introduction">
              Introduction<span className="required">*</span>
            </label>
            <Input.TextArea
              id="introduction"
              name="introduction"
              rows={5}
              value={requestDetail.introduce}
              disabled
            />
          </FormControl>
        </div>
        <div className="dp-flex mb-10">
          <FormControl>
            <label htmlFor="phone">
              Phone number<span className="required">*</span>
            </label>
            <Input
              id="phone"
              name="phone"
              value={requestDetail.phone_number}
              disabled
            ></Input>
          </FormControl>
          <FormControl>
            <label htmlFor="type">
              Type<span className="required">*</span>
            </label>
            <Input
              id="type"
              name="type"
              value={requestDetail.type}
              disabled
            ></Input>
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl>
            <label htmlFor="aspectRatio">
              Aspect Ratio<span className="required">*</span>
            </label>
            <Input
              id="title"
              name="aspectRatio"
              value={aspectRatioValue}
              disabled
            ></Input>
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl>
            <label htmlFor="status">
              Status<span className="required">*</span>
            </label>
            <Input
              id="status"
              name="status"
              value={requestDetail.status}
              disabled
            ></Input>
          </FormControl>
        </div>
        <div className="mb-10">
          <FormControl>
            <label htmlFor="message">
              Message<span className="required">*</span>
            </label>
            <Input.TextArea
              id="message"
              name="message"
              rows={5}
              value={requestDetail.message}
              disabled
            />
          </FormControl>
        </div>
        <div className="mb-10">
          <label style={{ color: "#fff" }}>
            Contain<span className="required">*</span>
          </label>
          {requestDetail.type !== TYPE_FILE_UPPERCASE.SLIDE ? (
            <div>
              {requestDetail.type === TYPE_FILE_UPPERCASE.IMAGE && (
                <div className='border-img'>
                  <ImageWrapper
                    className="preview-image"
                    onClick={() => {
                      setModalPreview(true);
                      setSrcSelected(requestDetail.content);
                    }}
                  >
                    <img
                      src={requestDetail.content}
                      alt="img"
                      className="image"
                    />
                    <div className="icon" onClick={() => {
                      setModalPreview(true);
                      setSrcSelected(requestDetail.content);
                    }}>
                      <EyeOutlined
                        className="icon-eye"
                        style={{ fontSize: "24px" }}
                      />
                    </div>
                  </ImageWrapper>
                </div>
              )}
              {requestDetail.type === TYPE_FILE_UPPERCASE.VIDEO && (
                <div className="preview-video">
                  <button
                    className="btn-preview"
                    onClick={() => {
                      setModalPreview(true);
                      setSrcSelected(requestDetail.content);
                    }}
                  >
                    <img src={Images.ICON_PLAY} alt="" />
                  </button>
                  <ReactPlayer
                    url={requestDetail.content}
                    className="video"
                    width="300px"
                    height="100%"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="dp-flex">
              {requestDetail.slides.map((item, index) => (
                <div className="border-img" key={index}>
                  <ImageWrapper
                    className="preview-image"
                    onClick={() => {
                      setModalPreview(true);
                      setSrcSelected(item);
                    }}
                  >
                    <img src={item} alt="img" className="image-slide" />
                    <div className="icon" onClick={() => {
                      setModalPreview(true);
                      setSrcSelected(item);
                    }}>
                      <EyeOutlined
                        className="icon-eye"
                        style={{ fontSize: "24px" }}
                      />
                    </div>
                  </ImageWrapper>
                </div>
              ))}
            </div>
          )}
        </div>
        {requestDetail.type === TYPE_FILE_UPPERCASE.VIDEO && (
          <div className="mb-10">
            <label style={{ color: "#fff" }}>
              Thumbnail<span className="required">*</span>
            </label>

            <div className="border-img"> 
              <ImageWrapper
                className="preview-image"
                onClick={() => {
                  setModalPreview(true);
                  setThumbnail(true);
                  setSrcSelected(requestDetail.thumbnail);
                }}
              >
                <img src={requestDetail.thumbnail} alt="img" className="image" />
                <div className="icon" onClick={() => {
                  setModalPreview(true);
                  setThumbnail(true);
                  setSrcSelected(requestDetail.thumbnail);
                }}>
                  <EyeOutlined
                    className="icon-eye"
                    style={{ fontSize: "24px" }}
                  />
                </div>
              </ImageWrapper>
            </div>
          </div>
        )}
        <div className="dp-flex btn-group">
          <ButtonImage
            onClick={() => {
              setModalDetail(false);
            }}
            type="cancel"
            height={40}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
          >
            Cancel
          </ButtonImage>

          <ButtonImage
            onClick={onDelete}
            type="delete"
            height={40}
            textTransform="unset"
            fontWeight={300}
            fontSize={14}
          >
            Delete
          </ButtonImage>
        </div>
      </Form>

      {modalPreview && (
        <PreviewModalWrapper
          open={modalPreview}
          onCancel={() => {
            setModalPreview(false);
            setThumbnail(false);
            setSrcSelected('');
            setPlayerState({});
          }}
          footer={null}
          centered
          closeIcon={<img src={Images.ICON_CLOSE_2} alt="close-icon" />}
          width={width} height={height}
        >
          {isThumbnail && (
            <img
              className="preview  preview-modal"
              src={srcSelected}
              alt="Preview"
            />
          )}
          {(requestDetail.type === TYPE_FILE_UPPERCASE.IMAGE ||
            requestDetail.type === TYPE_FILE_UPPERCASE.SLIDE) && (
            <img
              className="preview"
              src={srcSelected}
              alt="Preview"
              width={width}
              height={height}
              // style={{ maxWidth: '1000px', maxHeight: '80vh' }}
            />
          )}
          {requestDetail.type === TYPE_FILE_UPPERCASE.VIDEO && !isThumbnail && (
            <div className="video-content">
              <video
                disablePictureInPicture
                // style={{ maxWidth: '1000px', maxHeight: '80vh' }}
                className="custom-video"
                ref={videoRef}
                onPlay={handlePlayVideo}
                onPause={handlePlayVideo}
              >
                <source src={srcSelected} type="video/mp4" />
              </video>
              <div className="controls">
                <div className="name-video" style={{ width: 'calc(100% - 170px)' }}>{requestDetail.name}</div>
                <div style={{ position: 'absolute', top: '5px', right: '10px', display: "flex", color: "#c7c5c5", fontSize: "16px", gap: '5px'}}>
                  <div>{currentTime}</div>
                  <div>/</div>
                  <div>{totalTime}</div>
                </div>

                <input
                  className="range"
                  type="range"
                  min="0"
                  max="100"
                  value={playerState.progress ?? 0}
                  onChange={(e) => handleVideoProgress(e)}
                />

                <button className="btn-control btn-play" onClick={togglePlay}>
                  {playerState.isPlaying && playerState.progress < 100 ? (
                    <img src={Images.ICON_PAUSE_VID} alt="" />
                  ) : (
                    <img src={Images.ICON_PLAY_VID} alt="" />
                  )}
                </button>

                <button className="btn-control btn-prev" onClick={skipBackward}>
                  <img src={Images.ICON_PREV} alt="" />
                </button>
                <button className="btn-control btn-next" onClick={skipForward}>
                  <img src={Images.ICON_NEXT} alt="" />
                </button>

                <button className="btn-control btn-mute" onClick={toggleMute}>
                  {playerState.isMuted ? (
                    <img src={Images.ICON_MUTE} alt="" />
                  ) : (
                    <img src={Images.ICON_UN_MUTE} alt="" />
                  )}
                </button>
                <button
                  className="btn-control btn-full"
                  onClick={toggleFullScreen}
                >
                  <img src={Images.ICON_SHOW_FULL_SCREEN} alt="" />
                </button>
              </div>
            </div>
          )}
        </PreviewModalWrapper>
      )}
    </DetailModalWrapper>
  );
};

export default DetailModal;
