const WEEKLY = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const MONTHLY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 18, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
const DAILY = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11','12', '13', '14', '15', '16', '17','18', '19', '20', '21', '22', '23'];

export const TIME_TYPE = {
  WEEKLY,
  MONTHLY,
  DAILY
}

export const CHART_TIME = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  DAILY: 'DAILY'
}

export const option = {
  grid: { top: 24, right: 0, bottom: 24, left: 36 },
  xAxis: {
    axisLine: {
      symbol: 'line',
      lineStyle: {
        type: 'dashed',
        color: 'rgba(43, 106, 230, 0.5)',
      },
      splitLine: {
        show: false
      }
    },
    axisLabel: {
      align: 'center',
      color: 'white'
    },
    axisLine: {
      show: false
    },
    boundaryGap: true,
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        color: 'rgba(43, 106, 230, 0.5)',
        type: 'dashed',
        show: false
      }
    },
    axisLabel: {
      color: 'rgba(209, 209, 209, 1)',
      fontSize: 14,
      fontFamily: 'Noto Sans JP'
    },
    boundaryGap: true,
  },
};

export const ADVERTISE_PATHNAME = ['/management', '/advertise', '/statistics', '/profile']